<template>
    <div>
        <scale-loader v-show="isLoading" />
        <div v-show="!isLoading" ref="ganttContainer" style="height: 82vh; width: 100%;"></div>
    </div>
</template>

<script>
import '@bryntum/gantt-trial/gantt.material.css';
import { Gantt, Toolbar } from '@bryntum/gantt-trial';
import axios from "axios";
import moment from 'moment';
import Vue from "vue";
import { mapGetters } from "vuex";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

Vue.use(moment);

let labels = {
    activity: [
        'activity_status_assigned',
        'activity_status_running',
        'activity_status_paused',
        'activity_status_issue',
        'activity_status_done',
        'activity_status_reopened',
        'activity_status_closed',
        'activity_status_delayed_start'
    ],
    assignment: [
        'ok',
        'pending',
        'rejected'
    ]
}

let colors = {
    activity: [
        //assigned 0
        "#B8C2CC",
        //running 1
        "#3490dc",
        //paused 2
        "#F6993F",
        //issue 3
        "#e3342f",
        //done 4
        "#6574CD",
        //reopened 5
        "#9561E2",
        //closed 6
        "#38C172",
        //delayed_start 7
        "#fc8a18"
    ],
    assignment: [
        // 0 OK
        "#38C172",
        // 1 PENDING
        "#F6993F",
        // 2 REJECTED
        "#e3342f",
    ]
}

export default {
    name: 'gantt-v2',

    components: {
        ScaleLoader
    },

    data() {
        return {
            data: [],
            dependencies: [],
            params: {
                pg: 1,
                s: 1,
                light: 1,
                vs_gantt: 1
            },
            ganttInstance: null,
            criticalPaths: false,
            enableDependencies: true,
            enableBaselines: false,
            isLoading: true,
            rowHeight: 36,
            barMargin: 7,
            criticalColor: '#cc2d29'
        }
    },

    computed: {
        ...mapGetters({
            project: 'app/project'
        }),
    },

    mounted() {
        this.params.p = this.project.id;
        this.getActivitiesList(this.params)
    },
    beforeDestroy() {
        if (this.ganttInstance) {
            this.ganttInstance.destroy();
        }
    },

    methods: {
        // gantt methods
        initGantt() {
            if (this.ganttInstance) {
                this.ganttInstance.destroy();
            }
            this.ganttInstance = new Gantt({
                appendTo: this.$refs.ganttContainer,
                project: {
                    tasksData: this.data,

                    dependencies: this.dependencies,
                    autoCalculatePercentDoneForParentTasks: false,
                },
                height: '100%', //'calc(100vh - 20px)',
                features: {
                    taskMenu: { disabled: true },
                    taskResize: { disabled: true },
                    dependencies: { allowCreate: false, disabled: !this.enableDependencies },
                    percentBar: { allowResize: false },
                    rowReorder: { disabled: true },
                    baselines: { disabled: !this.enableBaselines },
                    taskTooltip: {
                        template: ({ taskRecord }) => {
                            return `
                            <div class="tooltip-container">
                                <div><b>${taskRecord.name}</b></div>
                                <div><b>Progreso:</b> ${taskRecord.percentDone.toFixed(2)}%</div>
                                <div><b>Fecha Inicio:</b> ${new Date(taskRecord.startDate).toLocaleDateString('es-ES')}</div>
                                <div><b>Fecha Fin:</b> ${new Date(taskRecord.endDate).toLocaleDateString('es-ES')}</div>
                            </div>
                        `;
                        },
                    },
                    cellTooltip: { disabled: false, hideDelay: 0, hoverDelay: 0 },
                },
                listeners: {
                },
                taskRenderer({ taskRecord }) {
                    return [
                        {
                            tag: 'div',
                            class: 'b-name',
                            text: taskRecord.percentDone.toFixed(2) + '%'
                        }
                    ];
                },
                subGridConfigs: {
                    locked: { flex: 3 },
                    normal: { flex: 7 }
                },
                scrollTaskIntoViewOnCellClick: true,
                columns: {
                    data: [
                        { type: 'tree', field: 'planning_code', text: 'Código', width: 50 },
                        { field: 'name', text: 'Nombre', autoWidth: true, maxWidth: 400 },
                        { type: 'startdate', field: 'startDate', text: 'Fecha Inicio', width: 100 },
                        { type: 'enddate', field: 'endDate', text: 'Fecha Fin', width: 100 },
                        { type: 'duration', field: 'duration', text: 'Duración', width: 100, align: 'center' },
                        {
                            field: 'status',
                            text: 'Estado',
                            align: 'center',
                            width: 150,
                            htmlEncode: false,
                            renderer: ({ record }) => {
                                return `
                                <span
                                    class="uppercase text-white font-black text-center p-1 rounded"
                                    style="font-size: 9px; background: ${colors[record.type][record.status]}"
                                >
                                    ${this.$t(labels[record.type][record.status])}
                                </span>`;
                            }
                        },
                        { type: 'percentdone', text: 'Progreso', mode: 'circle', width: 70 },
                        {
                            field: 'user.avatar',
                            text: 'Usuario',
                            width: 70,
                            align: 'center',
                            htmlEncode: false,
                            renderer: ({ record }) => {
                                return `<img src="${record.user.avatar}" alt="User Avatar" style="width: 25px; height: 25px; border-radius: 50%;">`;
                            },
                            tooltipRenderer: ({ record }) => record.user.fullname
                        },
                        { type: 'check', text: 'Crítico', field: 'critical_path', width: 70, readOnly: true },
                    ],
                    autoTree: false
                },
                tbar: new Toolbar({
                    items: [
                        {
                            type: 'buttonGroup',
                            items: [
                                {
                                    type: 'button',
                                    icon: 'b-fa b-fa-cogs',
                                    cls: 'button button-primary',
                                    text: 'Configurar',
                                    toggleable: true,
                                    menu: {
                                        type: 'popup',
                                        anchor: true,
                                        cls: 'settings-menu',
                                        layoutStyle: {
                                            flexDirection: 'column'
                                        },
                                        onBeforeShow: this.onSettingsShow,

                                        items: [
                                            {
                                                type: 'slider',
                                                ref: 'rowHeight',
                                                text: 'Altura de filas',
                                                width: '12em',
                                                showValue: true,
                                                min: 30,
                                                max: 70,
                                                onInput: this.onSettingsRowHeightChange
                                            },
                                            {
                                                type: 'slider',
                                                ref: 'barMargin',
                                                text: 'Margen de barras',
                                                width: '12em',
                                                showValue: true,
                                                min: 0,
                                                max: 10,
                                                onInput: this.onSettingsMarginChange
                                            }
                                        ]
                                    }
                                },
                                {
                                    type: 'button',
                                    cls: 'button button-primary',
                                    pressedCls: 'button-primary-pressed',
                                    icon: 'b-fa b-fa-bars-staggered',
                                    text: 'Linea Base',
                                    toggleable: true,
                                    pressed: this.enableBaselines,
                                    onAction: this.onBaselineClick
                                },
                                {
                                    type: 'button',
                                    cls: 'button button-success',
                                    pressedCls: 'button-success-pressed',
                                    icon: 'b-fa b-fa-project-diagram',
                                    text: 'Dependencias',
                                    toggleable: true,
                                    pressed: this.enableDependencies,
                                    onAction: ({ source }) => {
                                        this.ganttInstance.features.dependencies.disabled = !source.pressed;
                                        this.enableDependencies = source.pressed
                                    }
                                },
                                {
                                    type: 'button',
                                    cls: 'button button-danger',
                                    pressedCls: 'button-danger-pressed',
                                    icon: 'b-fa b-fa-fire',
                                    text: 'Camino Critico',
                                    toggleable: true,
                                    pressed: this.criticalPaths,
                                    onAction: this.onCriticalPathsClick
                                },
                                {
                                    type: 'button',
                                    icon: 'b-fa b-fa-search-plus',
                                    cls: 'button button-primary',
                                    onAction: () => this.ganttInstance.zoomIn()
                                },
                                {
                                    type: 'button',
                                    icon: 'b-fa b-fa-search-minus',
                                    cls: 'button button-primary',
                                    onAction: () => this.ganttInstance.zoomOut()
                                },
                                {
                                    type: 'button',
                                    icon: 'b-fa b-fa-compress-arrows-alt',
                                    cls: 'button button-primary',
                                    tooltip: 'Ajustar contenido',
                                    onAction: () => this.ganttInstance.zoomToFit()
                                },
                                {
                                    type: 'button',
                                    icon: 'b-fa b-fa-expand-arrows-alt',
                                    cls: 'button button-primary',
                                    pressedCls: 'button-primary-pressed',
                                    tooltip: 'Pantalla Completa',
                                    toggleable: true,
                                    onAction: ({ source }) => {
                                        if (source.pressed) {
                                            this.ganttInstance.requestFullscreen();
                                        } else {
                                            this.ganttInstance.exitFullscreen();
                                        }
                                    }
                                },
                                // {
                                //     type: 'textfield',
                                //     width: 200,
                                //     cls: 'input',
                                //     label: 'With custom trigger',
                                //     onInput: ({ value }) => {
                                //         this.ganttInstance.store.filter({
                                //             property: 'name',
                                //             operator: '*',
                                //             value: value
                                //         });
                                //     }
                                // }
                            ]
                        },
                    ]
                }),
                rowHeight: this.rowHeight,
                barMargin: this.barMargin,
            });
            this.isLoading = false;

            this.ganttInstance.project.on('load', () => {
                this.ganttInstance.zoomToFit();
            });
        },

        onSettingsShow({ source }) {
            source.widgetMap.rowHeight.value = this.ganttInstance.rowHeight;
            source.widgetMap.barMargin.value = this.ganttInstance.barMargin;
        },

        onSettingsRowHeightChange({ source }) {
            this.ganttInstance.rowHeight = source.value;
            this.rowHeight = source.value;
        },

        onSettingsMarginChange({ source }) {
            this.ganttInstance.barMargin = source.value;
            this.barMargin = source.value;
        },

        onCriticalPathsClick({ source }) {
            this.criticalPaths = source.pressed;
            this.isLoading = true
            this.data = this.data.map(item => {
                if (item.critical_path) {
                    item.eventColor = source.pressed ? this.criticalColor : colors.activity[item.status];
                }

                item.children = item.children.map(item2 => {
                    if (item2.critical_path) {
                        item2.eventColor = source.pressed ? this.criticalColor : colors.activity[item2.status];
                    }
                    return item2
                })
                return item
            })
            this.initGantt()
        },

        onBaselineClick({ source }) {
            this.ganttInstance.features.baselines.disabled = !source.pressed;
            this.enableBaselines = source.pressed
        },

        // other methods

        async getActivitiesList(args = []) {
            this.isLoading = true;
            const { data } = await axios.get('/api/v2/activities', { params: args });

            if (data && data.activities) {
                this.data = this.mapActivities(data.activities);
                await this.getDependencies();
            } else {
                this.$notify.error('error_loading_activities')
            }
        },

        async getDependencies() {
            const { data } = await axios.get(`/api/v2/project/${this.project.id}/get-prelations`);

            if (data && data.data) {
                this.dependencies = data.data;
            }
            this.initGantt();
        },

        /**
         * Tras la llamada a la API la actividad debe procesarse
         */
        mapActivities(activities) {
            let clean = [];

            for (let i in activities) {
                let activity = activities[i];

                clean.push(this.mapActivity(activity));
            }

            return clean;
        },

        /**
         * Procesa los datos de una actividad, de las que fueron conseguidas via API
         */
        mapActivity(activity) {
            let clean = {
                children: []
            };
            clean.id = activity.id;
            clean.planning_code = activity.planning_code;
            clean.parentId = (activity.is_subactivity) ? activity.parent_id : null;
            clean.user = activity.manager;
            clean.startDate = moment(activity.current_start_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
            clean.endDate = moment(activity.current_end_date, 'DD-MM-YYYY').format('YYYY-MM-DD');

            //en el plugin gantt project se muestra lisa con picos y milestone es hexagonal
            clean.type = 'activity';
            clean.expanded = false;
            clean.draggable = false;
            clean.manuallyScheduled = true;
            clean.name = activity.name;
            clean.status = activity.status;
            clean.critical_path = activity.critical_path;
            clean.percentDone = parseFloat(activity.progress);
            clean.eventColor = activity.critical_path && this.criticalPaths ? this.criticalColor : colors.activity[activity.status];
            

            if (activity.subactivities?.length) {
                clean.children = activity.subactivities.map(subactivity => {
                    return this.mapActivity(subactivity);
                });
            }

            if (activity.assignments?.length) {
                activity.assignments.map(assignment => {
                    clean.children.push(this.mapAssignment(assignment, clean))
                });
            }

            return clean;
        },

        /**
         * Procesa los datos de una asignaciones que fueron conseguidas via API
         */
        mapAssignment(assignment, activity) {
            assignment.startDate = activity.startDate;
            assignment.endDate = activity.endDate;
            assignment.id = 't' + assignment.id;
            assignment.percentDone = parseFloat(this.calculatePercentage(assignment).toFixed(2));
            assignment.type = 'assignment';
            assignment.draggable = false;
            assignment.manuallyScheduled = true;
            assignment.name = assignment.description;
            assignment.status = assignment.status;
            assignment.eventColor = colors.assignment[assignment.status];

            return assignment;
        },

        calculatePercentage(assignment) {
            if (assignment.total_quantity != null) {
                return (100 / assignment.total_quantity) * assignment.executed_quantity;
            } else
                return (assignment.completed) ? 100 : 0;
        },
    }
};
</script>

<style>
    :root {
        --primary: #57b;
        --primary-hover: #68c;
        --primary-light: #e4ebf8;
        --primary-light-hover: #dce5f8;

        --success: #2a9d8f;
        --success-hover: #38b2a3;
        --success-light: #e3f6f5;
        --success-light-hover: #d4f1ef;

        --danger: #d62828;
        --danger-hover: #e63946;
        --danger-light: #f8e4e4;
        --danger-light-hover: #f8dcdc;
    }

    .b-grid-subgrid {
        background-image: none !important;
    }

    .b-gantt-task-parent .b-sch-label-top {
        position: relative;
        top: -0.25em;
    }

    .b-gantt-task-parent .b-sch-label-bottom {
        margin-top: -0.75em;
    }

    .b-sch-color-none.b-gantt-task-wrap.b-gantt-task-parent:not(.b-milestone-wrap) .b-gantt-task {
        min-height: 1.8em;
    }

    .b-gantt-task-content {
        display: flex;
    }

    .b-gantt-task-parent .b-gantt-task-content {
        display: flex;
        height: 1.1em;
        align-items: center;
        align-self: flex-start;
    }

    .b-hour-editor {
        box-shadow: none;
        border: 1px solid #aaa;
    }

    .b-hour-editor .b-field-inner {
        border: 0;
    }

    .b-hour-editor input {
        text-align: center;
    }

    .b-sch-event-wrap .b-sch-label-bottom,
    .b-gantt-task-wrap .b-sch-label-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        width: 100%;
        margin-top: 0;
    }

    .b-sch-event-wrap .b-sch-label-bottom .b-day-hours,
    .b-gantt-task-wrap .b-sch-label-bottom .b-day-hours {
        height: 100%;
        padding: 0.25em;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        text-align: center;
        font-weight: 400;
        font-size: 0.75rem;
    }

    .b-gantt-task-wrap .b-sch-label-top {
        overflow: hidden;
        font-weight: 400;
        font-size: 0.75rem;
        padding: 0.25em;
    }

    .b-gantt-task-wrap .b-sch-label-top i {
        margin-inline: 1em 0.5em;
    }

    .b-name {
        font-size: 0.75em;
        margin: auto;
    }

    .b-grid-header-container,
    .b-grid-headers .b-last-leaf {
        background: var(--primary);
        color: #fff;
        font-size: 14px;
    }

    .b-grid-header-text-content {
        color: #fff;
    }

    .b-resizing,
    .b-drop-placeholder {
        background-color: var(--primary-hover) !important;
    }

    .b-grid-header.b-drag-proxy {
        background-color: var(--primary-hover) !important;
    }

    .b-gridbase.b-sort .b-grid-header.b-sort .b-grid-header-text .b-sort-icon:before {
        color: #fff !important;
    }

    .b-horizontaltimeaxis .b-sch-header-timeaxis-cell {
        color: #fff;
    }

    .b-horizontaltimeaxis .b-sch-header-timeaxis-cell:hover {
        background: var(--primary-hover) !important;
    }

    .b-grid-splitter-inner {
        background: #57b3 !important;
    }

    .b-tree-cell-value,
    .b-grid-cell {
        font-size: 12px;
    }

    .b-panel.b-html .b-panel-content {
        background: var(--primary);
        padding: 12px;
        margin: 0;
        font-size: 14px;
    }

    .b-baseline-wrap {
        min-height: 14px;
    }

    .button {
        font-size: 12px;
        height: 10px;
    }

    .button-primary {
        color: var(--primary);
        background-color: var(--primary-light);
    }

    .button-primary:hover,
    .button-primary:focus {
        color: var(--primary);
        background-color: var(--primary-light-hover);
    }

    .button-primary-pressed {
        background-color: var(--primary);
        color: #fff;
    }

    .button-primary-pressed:hover,
    .button-primary-pressed:focus {
        background-color: var(--primary-hover);
        color: #fff;
    }


    .button-success {
        color: var(--success);
        background-color: var(--success-light);
    }

    .button-success:hover,
    .button-success:focus {
        color: var(--success);
        background-color: var(--success-light-hover);
    }

    .button-success-pressed {
        background-color: var(--success);
        color: #fff;
    }

    .button-success-pressed:hover,
    .button-success-pressed:focus {
        background-color: var(--success-hover);
        color: #fff;
    }

    .button-danger {
        color: var(--danger);
        background-color: var(--danger-light);
    }

    .button-danger:hover,
    .button-danger:focus {
        color: var(--danger);
        background-color: var(--danger-light-hover);
    }

    .button-danger-pressed {
        background-color: var(--danger);
        color: #fff;
    }

    .button-danger-pressed:hover,
    .button-danger-pressed:focus {
        background-color: var(--danger-hover);
        color: #fff;
    }

    /* .input {
        height: 20px;
        font-size: 13px;
    }

    .b-widget.b-textfield.b-empty:not(:focus-within):not(.b-open) label {
        top: .5em;
    } */
</style>
