<template>
<div class="activity-documents panel">
  <div class="flex items-center justify-between w-full">
    <!-- Título -->
    <div class="text-lg font-thin uppercase text-blue-light">{{ $t('tasks') }}</div>
    <!-- add task button -->
    <div class="flex items-center">
      <b-button
        @click="addTask"
        type="is-primary"
        icon-pack="fas"
        icon-left="plus"
        size="is-small"
        class="mr-2"
      >
        {{ $t('add_task') }}
      </b-button>
      </div>
  </div>

  <!-- modal with code name description fields -->
  <b-modal v-model="addTaskModal"
    scroll="clip"
    :class="{ 'max-width-custom': '35%' }"
    :width="modalWidth"
  >
  <div class="max-h-screen bg-white border rounded shadow overflow-y-none border-blue-light p-4 relative">
    <div v-if="! creatingTask" >
      <div class="field">
        <label class="label">{{ $t('task') }}</label>
        <div class="control">
          <input class="input" type="text" v-model="task" :placeholder="$t('task')">
        </div>
      </div>
      <div class="field">
        <label class="label">{{ $t('description') }}</label>
        <div class="control">
          <input class="input" type="text" v-model="description" placeholder="Description">
        </div>
      </div>
      <div class="flex flex-col">
        <b-field class="file is-success">
          <b-upload
            v-model="files"
            class="file-label"
            :multiple="true"
            :accept="accept"
          >
            <span class="file-cta">
              <span class="mr-2 file-label">{{
                $t("upload_documents")
              }}</span>
              <b-icon class="file-icon" icon="upload" pack="fas"></b-icon>
            </span>
          </b-upload>
        </b-field>
      </div> 
      
      <div class="tags" v-if="filesSelected">
            <span v-for="(file, index) in files"
              :key="index"
              class="tag is-primary"
            >
              {{ file.name }}
              <button
                class="delete is-small"
                type="button"
                @click="removeFile(index)"
              ></button>
            </span>
      </div>
      <div class="field">
        <div class="control">
          <button class="button is-primary" @click="addTasks">{{ $t('add_task') }}</button>
        </div>
      </div>
    </div>
    <div v-else class="mt-4 text-center">
      <div class="mt-4 text-center"><scale-loader></scale-loader></div>
    </div>
  </div>
  </b-modal>

  <!-- modal for complete observation y status -->
  <b-modal scroll="clip" :class="{ 'max-width-custom': '35%' }" :width="modalWidth" v-model="completeTaskModal">
    <div class="max-h-screen bg-white border rounded shadow overflow-y-none border-blue-light p-4 relative">
      <div v-if="! completingTask">
        <div class="field">
          <label class="label">{{ $t('observations') }}</label>
          <div class="control">
            <input class="input" type="text" v-model="observation" :placeholder="$t('observations')">
          </div>
        </div>
        <div class="field">
          <div class="control">

            <div class="flex flex-col">
              <b-field class="file is-success">
                <b-upload
                  v-model="files"
                  class="file-label"
                  :multiple="true"
                  :accept="accept"
                >
                  <span class="file-cta">
                    <span class="mr-2 file-label">{{
                      $t("upload_documents")
                    }}</span>
                    <b-icon class="file-icon" icon="upload" pack="fas"></b-icon>
                  </span>
                </b-upload>
              </b-field>
            </div> 
            
            <div class="tags" v-if="filesSelected"> 
                  <span v-for="(file, index) in files"
                    :key="index"
                    class="tag is-primary"
                  >
                    {{ file.name }}
                    <button
                      class="delete is-small"
                      type="button"
                      @click="removeFile(index)"
                    ></button>
                  </span>
            </div>

          </div>
        </div>
        <div class="field">
          <div class="control">
            <button class="button is-primary" @click="completeTask"> 
              {{ $t('to_complete') }} {{ $t('task') }}
            </button>
          </div>
        </div>
      </div>
      <div v-else class="mt-4 text-center">
        <div class="mt-4 text-center"><scale-loader></scale-loader></div>
      </div>
    </div>
  </b-modal>


  <!-- modal document preview -->
  <entity-document-preview
    :entity-id="entityId"
    :entity-type="entityType"
    ref="previewModal"
    :showVersions="false"
    @reload-documents-list="() => $emit('reload-documents-list')"
  />

  <div v-if="isLoading" class="mt-4 text-center">
        <div class="mt-4 text-center"><scale-loader></scale-loader></div>
  </div>

  <div v-if="isLoadingTasks" class="my-4 text-center">
    <scale-loader />
  </div>

  <div class="flex items-center my-4" v-if="! isLoadingTasks">
    <table class="w-full">
      <thead class="bg-blue-lightest">
        <tr>
          <th class="p-2 text-sm font-semibold text-center uppercase text-blue-dark"></th>
          <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("task") }}</th>
          <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("description") }}</th>
          <th class="p-2 text-sm font-semibold text-center uppercase text-blue-dark">{{ $t("status") }}</th>
          <th class="p-2 text-sm font-semibold uppercase text-blue-dark"></th>
        </tr>
      </thead>
      <tbody>

        <template v-if="tasksList.length == 0">
          <tr><td colspan="4"><div class="text-center font-semibold text-blue">{{ $t('no_results') }}</div></td></tr>
        </template>

        <template v-for="row in tasksList" >
          <!-- lista de tareas  -->
          <tr class="hover:bg-grey-lightest" :key="row.id">
            <td class="justify-start w-auto h-full p-4 align-middle">
              <div
              class="flex text-center align-middle cursor-pointer" @click="selectedHistory == row.id ? selectedHistory = null : selectedHistory = row.id">
                <b-tooltip
                  :label="$t('display_history', 2)"
                  position="is-right"
                >
                  <span class="mr-1 text-xs font-semibold text-blue">
                    {{ row.issue_task_lines.length }}
                  </span>
                  <b-icon icon="history" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon>
                  <b-icon :icon=" (row.id == selectedHistory) ? 'chevron-up' : 'chevron-down'" pack="fas" size="is-small" class="text-blue"></b-icon>
                </b-tooltip>
              </div>
            </td>

            <td class="p-2">
              <div class="text-xs font-semibold">{{ row.name }}</div>
            </td>
            <td class="w-2/5 p-2">
              <div class="flex flex-col">
                <div class="text-xs" v-html="row.description"></div>
                <div class="text-xs font-semibold">
                  
                <!-- verificamos si tiene documents row.documents -->
                  <template >
                    <b-tooltip v-for="rowDocument in row.documents" :key="rowDocument.id" :label="rowDocument.original_filename" position="is-top" >
                      <div @click="showPreview(rowDocument)" class="w-12 h-12 m-1 rounded-lg shadow cursor-pointer bg-grey">
                        <img class="w-12 h-12 rounded-lg" src="https://epc-tracker-assets.s3.eu-west-3.amazonaws.com/public/document.jpeg" onerror="this.src='https://epc-tracker-assets.s3.eu-west-3.amazonaws.com/public/document.jpeg'" /> 
                      </div>
                    </b-tooltip>

                  </template>
                </div>
              </div>
            </td>
            <td class="p-4">
              <div class="flex justify-start w-auto h-full align-middle" v-if="row.status == 0">
                <div class="px-2 py-1 text-xs text-white rounded-full bg-yellow">{{ $t('pending') }}</div>
              </div>
              <div class="flex justify-start w-auto h-full align-middle" v-else-if="row.status == 2">
                <div class="px-2 py-1 text-xs text-white rounded-full bg-orange">{{ $t('pending_verify') }}</div>
              </div>
              <div class="flex justify-start w-auto h-full align-middle" v-else-if="row.status == 1">
                <div class="px-2 py-1 text-xs text-white rounded-full bg-green">{{ $t('completed') }}</div>
              </div>
              <!-- otro estado rechazado  -->
               <div class="flex justify-start w-auto h-full align-middle" v-if="row.status == 3">
                <div class="px-2 py-1 text-xs text-white rounded-full bg-red">{{ $t('rejected') }}</div> 
              </div>
            </td>

            <td class="justify-center w-auto h-full p-4 align-middle">
              <div class="text-center cursor-pointer">
                <b-dropdown aria-role="list" position="is-bottom-left">
                  <template #trigger="{ active }">
                    <b-icon :icon="active ? 'ellipsis-h' : 'ellipsis-v'" pack="fas" size="is-small" class="text-blue"></b-icon>
                  </template>
                  <b-dropdown-item v-if="row.status != 1 && row.status != 2 && project.can_issue_resolutor "
                   aria-role="listitem" 
                    @click="()=>{
                      completeRow = row;
                      completeTaskModal = true;
                      statusComplete = 0; // pendiente de validar
                    }
                    "

                  >
                    <span class="text-xs">{{ $t('complete_resource') }}</span>
                  </b-dropdown-item>
                  <!-- cuando estatus 
                    -->
                  <b-dropdown-item v-if="row.status == 2 && project.can_issue_admin" 
                    aria-role="listitem" @click="()=>{
                      completeRow = row;
                      completeTaskModal = true;
                      statusComplete = 1;// verificar 
                    }">
                    <span class="text-xs">{{ $t('validate') }}</span>  
                  </b-dropdown-item>
                  <b-dropdown-item v-if="row.status == 2 && project.can_issue_admin" 
                    aria-role="listitem" @click="()=>{
                      completeRow = row;
                      completeTaskModal = true;
                      statusComplete = 2;// rechazar
                    }">
                    <span class="text-xs">{{ $t('reject') }}</span>
                  </b-dropdown-item>

                </b-dropdown>
              </div>
            </td>
          </tr>
          <!-- historial de acciones -->
          <tr  :key="'task_history_'+row.id">
            <td colspan="5" :class="{'hidden': !(row.id == selectedHistory)}" >
              <table class="w-full">
                <tr class="bg-indigo-lightest">
                  <td colspan="6" class="p-4">
                    <div class="flex justify-start w-auto h-full align-middle">
                    <b-icon icon="history" pack="fas" size="is-small" class="ml-1 text-blue"></b-icon>
                    <span class="ml-2 text-xs font-semibold text-blue">{{$t('history_actions') }}</span>
                    </div>
                  </td>
                </tr>
                <tr class="bg-blue-lightest">
                  <th align="center" class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('date') }}</th>
                  <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('user') }}</th>
                  <th align="center" class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('observations') }}</th>
                  <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('status') }}</th>
                </tr>
                <tbody>
                  <tr v-for="history in row.issue_task_lines" :key="history.id">
                    <td class="p-2 text-xs">
                      {{ formatDate(history.created_at) }}</td>
                    <td class="p-2 text-xs">
                      <div class="flex flex-row items-center justify-start w-auto h-full">
                        <div class="w-8 h-8 rounded-full">
                          <img class="w-8 h-8 rounded-full" :src="history.user.basic.avatar"/>
                        </div>
                        <div class="flex flex-col ml-2">
                          <div class="text-xs">{{ history.user.basic.fullname}}</div>
                        </div>
                      </div>
                      <!-- {{ complete.user}} -->
                    </td>
                    <td class="w-2/5 p-2">
                      <div class="flex flex-col">
                        <div class="text-xs" v-html="history.observations"></div>
                        <div class="text-xs font-semibold">
                          <b-tooltip v-for="document in history.documents" :label="document.original_filename" position="is-top" :key="document.id">
                            <div @click="showPreview(document)" class="w-12 h-12 m-1 rounded-lg shadow cursor-pointer bg-grey">
                              <img class="w-12 h-12 rounded-lg" src="https://epc-tracker-assets.s3.eu-west-3.amazonaws.com/public/document.jpeg" onerror="this.src='https://epc-tracker-assets.s3.eu-west-3.amazonaws.com/public/document.jpeg'" /> 
                            </div>
                          </b-tooltip>
                        </div>
                      </div>
                    </td>
                    <td class="p-2 text-xs">
                      <!-- pendiente , verifiado,  rechazado
                                          '0' => 'pending',
                    '1' => 'verified',
                    '2' => 'rejected',
                       -->
                      <span v-if="history.status == 1" class="px-2 py-1 text-xs text-white rounded-full bg-green">Verificado</span>
                      <span v-else-if="history.status == 0" class="px-2 py-1 text-xs text-white rounded-full bg-orange">Pendiente</span>
                      
                      <span v-else class="px-2 py-1 text-xs text-white rounded-full bg-red">Rechazado</span>
                    </td>
                    
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </template>
      </tbody>

    </table>

  </div>
</div>
</template>

<script>
import axios from "axios";
import EventBus from "~/plugins/bus";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import validationStatusLabel from '·/components/validations/validation-status-label';
import validationTaskStatusLabel from '·/components/validations/validation-task-status-label';
import entityDocumentPreview from "./entity-document-preview";
import { mapGetters } from "vuex";
import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import store from "~/store";
import moment from 'moment';


export default {
  name: "entity-validationflows",

  components: {
    ScaleLoader,
    entityDocumentPreview,
    validationStatusLabel,
    validationTaskStatusLabel
  },

  props: {
    entityId: { type: Number, required: true },
    entityType: { type: String, required: true },
  },

  data: () => ({
    modalWidth: '35%',
    flowsList: [],
    isLoading: true,
    isLoadingTasks: true,
    orderField: 'name',
    document: null,
    files: [],
    accept: "",
    tasksList: [],

    addTaskModal: false,
    task: "",
    description: "",
    selectedHistory: null,

    statusComplete: 1,
    observation: "",
    completeTaskModal: false,

    completeRow: null,

    addDocumentRef: null,
    cancelRequest: null,
    uploadPercentage: 0,
    
    creatingTask: false,
    completingTask: false,

  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      project: 'app/project',
      company: 'app/company',
    }),
  },

  created() {
    this.getTasksList();
    
    // Referenciamos
    var self = this;

    this.loadFlowsList(this.entityId, this.entityType);
    if (this.entityType == 'activity') {
      this.getTasksList();
    }

    // Si recibimos el evento refresh, refrescamos el componente
    EventBus.$on('refreshValidations', function (e) {
      if (!e || !e.validation_flow_id || self.flowsList.some(v => v.id == e.validation_flow_id)) {
        self.isLoading = true;
        self.loadFlowsList(self.entityId, self.entityType);
      }
    });

    this.uppy = new Uppy({
        debug: process.env.NODE_ENV === 'development', // activamos debug para development
        autoProceed: false,
        allowMultipleUploads: false,
      })
      .use(AwsS3Multipart, {
        limit: 3,
        companionUrl: '/api/v2/',
        companionHeaders: {
          'Authorization': "Bearer " + store.getters["auth/token"]
        }
      })
      // .on('upload-success', (file, response) => { this.onUploadSuccess(file, response) } )
      .on('upload-success', (file, response) => { // callback deveulto cuando el file ha sido subido correctamente
        console.log('%cEPC-TACKER: '+ '%c file ' + file.data.name + ' subido correctamente', 'background: #5577BB; color: #fff', 'color: #000')
      })
      .on('upload-progress', (file, progesss) => { // callback devuelto con el progreso de subido del archivo
        this.uploadPercentage = parseInt(
          Math.round((progesss.bytesUploaded * 100) / progesss.bytesTotal)
        );
      })
      .on('upload-error', (file, error, response) => { // callback devuelto si ha ocurrido algun error en la subida
        console.error('%cEPC-TACKER: '+ '%c a ocurrido un error al subir el archivo ' + file.data.name, 'background: #5577BB; color: #fff', 'color: #000', error)
      })

  },

  beforeDestroy(){
  	EventBus.$off("refreshValidations")
  },


  methods: {
    getHeaderClass(field) {
      return field === "status" || field === "actions"
        ? "text-center"
        : "text-left";
    },

    formatDate(date) {
      return moment(date).format('DD/MM/YYYY HH:mm')
    },

    addTask() {
      this.addTaskModal = true;
    },

    filesSelected() {
      return this.files.length > 0 || this.files.size > 0;
    },

    showPreview(document) {
      this.document = document;
      this.$refs.previewModal.show(this.document);      
    },

    addTasks() {
      this.creatingTask = true;
      const data = {
        task: this.task,
        description: this.description,
        status: 2,
        user_id: this.user.id,
        issue_id: this.entityId
      };
      const url = '/api/v2/issue/'+ data.issue_id +'/issue-task';

      axios.post(url, data)
        .then((response) => {
          if (response.data.success) {
            this.task = "";
            this.description = "";
            this.getTasksList();
            this.uploadFiles('issue_task', response.data.issueTask.id, 'task');
          } else {
            this.$notify.error("error_adding_task");
          }
        })
        .catch((error) => {
          this.$notify.error("error_adding_task");
          console.log(error);
        }).finally(() => {
          this.isLoading = false;
          this.getTasksList();
        });
    },

    getStatusClass(status) {
      return status === "Completed"
        ? "text-green-600 font-semibold"
        : "text-red-600 font-semibold";
    },
    async loadFlowsList(entityId, entityType) {

      this.isLoading = true
      let self = this

      let params = {
        per_page: this.perPage,
        page: this.page,
        order_field: this.orderField,
        // sin filro status, para que devuelva todas, incluso completadas
      }

      if (entityType == 'project') {
        params.p = entityId
      } else if (entityType == 'activity') {
        params.a = entityId
      } else if (entityType == 'issue') {
        params.i = entityId
      }

      await axios.get("/api/v2/validations", { params: params })
        .then(function(response) {

          if (response.data.validations) {
            self.flowsList = response.data.validations;
          }

        })
        .catch(function(error) {
          self.$notify.error('error_loading_validations')
          console.log(error);
        })
        .finally(function () {
          self.isLoading = false;
        });
    },

    currentTask(task) {
      return task ? task.name : '-'
    },


    // Go to validationflow home
    goWithTheFlow(validationId) {
        this.$router.push({name: 'validation.home', params: {validation_id: validationId}})
    },

    async getTasksList() {
      let {data} = await axios.get(`/api/v2/issue/${this.entityId}/issue-tasks`);
      if (data.success) {
        this.tasksList = data.issueTasks;
        
        this.isLoadingTasks = false;
      } else {
        this.$notify.error("error_retrieving_data");
      }
    },

    async completeTask () {

      this.completingTask = true;
      const data = {
        observations: this.observation,
        status: this.statusComplete,
        issue_task_id: this.completeRow.id,
        user_id: this.user.id
      };
      const url = `/api/v2/issue-task/${this.completeRow.id}/issue-task-line`;
      axios.post(url, data)
        .then((response) => {
          if (response.data.success) {
            this.observation = "";
            this.statusComplete = 1;
            
            this.uploadFiles('issue_task_line', response.data.issueTaskLine.id);
            this.completeRow = null;
            
          } else {
            this.$notify.error("error_completing_task");
          }
        })
        .catch((error) => {
          this.$notify.error("error_completing_task");
          console.log(error);
        }).finally(() => {
          this.isLoading = false;
          
        });

    },

    async uploadFiles(entity, id, action = null) {
      if (this.files.length == 0) {
        this.creatingTask = false;
        this.completingTask = false;
        this.$notify.success("task_completed");
        this.completeTaskModal = false;

        this.$notify.success("task_created");
        this.addTaskModal = false;

        this.getTasksList();
        return;
      }

      for (const file of this.files) {
        try {
          const result = await this.submitFile(file, entity, id);
          
          if (this.files.indexOf(file) == this.files.length - 1) {
            if (action == 'task'){
              this.creatingTask = false;
              this.$notify.success("task_created");
              this.addTaskModal = false;
            } else {
              this.completingTask = false;
              this.completeTaskModal = false;
            }
            // limpiamos los archivos
            this.files = [];
          }
        } catch (error) {
          console.error(`Error en el archivo ${file.name}:`, error);
          this.$notify.error(`Error crítico al subir ${file.name}`);
        }

      }
      // Limpiamos los archivos
      // this.files = [];

    },

    async submitFile(file, entity, id) {

      this.uploadPercentage = 0;
      this.cancelRequest = axios.CancelToken.source();


      let form = new FormData();

      let customName = "";
      if (file.custom_name) customName = file.custom_name.trim();

      // Si el nombre personalizado después del trim tiene contenido usamos ese, y si no pasamos al del fichero
      form.append(
        "original_filename",
        customName != "" ? customName + this.getFileExtension(file) : file.name
      );
      form.append("entity_type", entity);
      form.append("entity_id", id);

      form.append("project_id", this.project.id)

      return this.store(file, {}).then(async result => {
            // successFul contiene toda la lista de archivos que se han subido
            // para nuestro caso solo consideramos siempre el primero por que enviamos de a uno
            
            if( result.successful.length > 0 ) {
              let response = result.successful[0].response

              let params = {
                // uuid: response.uuid,
                key: response.body.key,
                // bucket: response.bucket,
                name: file.name,
                content_type: file.type,
                // url: response.url
              }

              form.append("aws_response", JSON.stringify(params));

              let resp = null
              
              resp = await axios.post("/api/v2/issue/" + this.entityId + "/document", form, {
                  cancelToken: this.cancelRequest.token,
                });

              this.getTasksList();

              return resp && resp.data && resp.data.success ? resp.data.success : false
            }

            // failed contiene todos los archivos que tubieron fallo al subir
            if (result.failed.length > 0) {
              
              result.failed.forEach((file) => {
                console.error(file.error)
              })

              return false
            }
      }).catch((error) => {
        console.log('error: ', error)
        return false
      });

    },
    async store(file, options = null) {
      // verificamos si existe algun archivo en la lista de carga de uppy
      // si existe lo eliminamos
      if( this.keyFileUploadCurrent ) {
        this.uppy.removeFile(this.keyFileUploadCurrent);
      }

      if (typeof options.progress === 'undefined') {
          options.progress = () => {};
      }

      this.keyFileUploadCurrent = this.uppy.addFile({
        name: file.name, // file name
        type: file.type, // file type
        data: file, // file blob
        // meta: {
        //   // optional, store the directory path of a file so Uppy can tell identical files in different directories apart.
        //   relativePath: webkitFileSystemEntry.relativePath,
        // },
        source: 'Local',
        isRemote: false,
      });

      // response.data.extension = file.name.split('.').pop()
      return this.uppy.upload()
    },




  }
};
</script> 
